export default class PledgePayement extends HTMLDivElement {

    constructor() {
        super();
        this.metadatas = null;
        this.backUrl = null;
    }
    
    connectedCallback() {
        const url = this.getAttribute('data-url');
        this.getData(url);
    }

    getData(url) {
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                this.metadatas = data[0];
                this.backUrl = data[1];
                this.querySelector('.back-success').setAttribute('data-url', this.backUrl.successUrl);
                this.querySelector('.back-error').setAttribute('data-url', this.backUrl.errorUrl);
                this.getInitDatas();
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    getInitDatas() {
        const btn = this.querySelector('.trigger-plegde');
        if(btn.classList.contains('selected-pledge')) {
            this.initPledge(this.metadatas);
            btn.click();
        }
    }

    initPledge(datas) {

        var header = {
            alg: "HS256",
            typ: "JWT",
        };

        var data = {
            data: datas,
        };

        var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        var encodedHeader = this.base64url(stringifiedHeader);
        var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        var encodedData = this.base64url(stringifiedData);
        var token = encodedHeader + "." + encodedData;
        //var secret = "My very confidential secret!";
        var secret = "rplFeKEqiOnygtszFh6yDzyOsBj5eCPmW50vM3T543Q";
        var signature = CryptoJS.HmacSHA256(token, secret);
        signature = this.base64url(signature);
        var signedToken = token + "." + signature;

        document.getElementById("ref").textContent = data.data.reference;
        document.getElementById("amount").textContent = data.data.amountCents;
        document.getElementById("token").textContent = token;
        document.getElementById("signedToken").textContent = signedToken;

        var button = this;

        const containerElement = this.querySelector(".pledg-funnel-container");

        new Pledg(button, {
            signature: signedToken,
            containerElement: containerElement,
            // the function which triggers the payment
            onSuccess: function (result) {

                
                let url = document.querySelector('.back-success');

                let jsonString = JSON.stringify(result);
                // Encode the JSON string to make it URL-safe
                let encodedJsonString = encodeURIComponent(jsonString);
                // Construct the URL with the encoded JSON string as a parameter
                let redirect = `${url.getAttribute('data-url')}&data=${encodedJsonString}`;
                
                location.href = redirect;
            },
            onError: function (error) {
                var ErroMsg = error.message + " (" + error.type + ")";
                const url = document.querySelector('.back-error');
                location.href = url.getAttribute('data-url');
            },
        });
    }

    getRandomText() {
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var randomtext = "";
        for (var i = 0; i < 8; i++)
            randomtext += possible.charAt(
                Math.floor(Math.random() * possible.length)
            );
        return randomtext;
    }

    base64url(source) {
        // Encode in classical base64
        
        let encodedSource = CryptoJS.enc.Base64.stringify(source);

        // Remove padding equal characters
        encodedSource = encodedSource.replace(/=+$/, "");

        // Replace characters according to base64url specifications
        encodedSource = encodedSource.replace(/\+/g, "-");
        encodedSource = encodedSource.replace(/\//g, "_");

        return encodedSource;
    }
}
